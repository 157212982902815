<template>
  <div class="w-full py-6">
    <h1 class="text-center text-2xl font-bold text-[#001C54]">
      Rankings & Recognitions
    </h1>
    <div class="relative max-w-7xl mx-auto">
      <div
        :class="`prev_ranking`"
        class="absolute top-[30%] md:top-[40%] left-2 lg:-left-10 z-20 hidden"
      >
        <img src="@/assets/slide-left.png" alt="" class="w-7 md:w-10" />
      </div>
      <div
        :class="`next_ranking`"
        class="absolute top-[30%] md:top-[40%] right-2 lg:-right-10 z-20 hidden"
      >
        <img src="@/assets/slide-right.png" alt="" class="w-7 md:w-10" />
      </div>
      <div class="slick_ranking h-full gap-4 pt-4 md:py-6">
        <div
          class="flex flex-col justify-center items-center gap-2 p-2 py-3 w-[25%] h-[160px] rounded-md"
        >
          <div class="inline-block mx-auto">
            <img
              :src="this.university_details.ugc_icons"
              alt="in-demand elective icon"
              class="w-40 h-20 object-contain"
            />
          </div>
          <p class="text-xs text-gray-500 px-4">
            {{ this.university_details.ugc_ranking }}
          </p>
        </div>

        <div
          class="flex flex-col justify-center items-center gap-2 p-2 py-3 w-[25%] h-[160px] rounded-md"
        >
          <div class="inline-block mx-auto">
            <img
              :src="this.university_details.naac_icons"
              alt="in-demand elective icon"
              class="w-40 h-20 object-contain"
            />
          </div>
          <p class="text-xs text-gray-500 px-4">
            {{ this.university_details.naac_ranking }}
          </p>
        </div>
        <div
          class="flex flex-col justify-center items-center gap-2 p-2 py-3 w-[25%] h-[160px] rounded-md"
        >
          <div class="inline-block mx-auto">
            <img
              :src="this.university_details.qs_icons"
              alt="in-demand elective icon"
              class="w-40 h-20 object-contain"
            />
          </div>
          <p class="text-xs text-gray-500 px-4">
            {{ this.university_details.qs_ranking }}
          </p>
        </div>
        <div
          class="flex flex-col justify-center items-center gap-2 p-2 py-3 w-[25%] h-[160px] rounded-md"
        >
          <div class="inline-block mx-auto">
            <img
              :src="this.university_details.nirf_icons"
              alt="in-demand elective icon"
              class="w-40 h-20 object-contain"
            />
          </div>
          <p class="text-xs text-gray-500 px-4">
            {{ this.university_details.nirf_ranking }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "slick-carousel";
export default {
  data() {
    return {
      university_details: [],
    };
  },
  created() {
    this.universityCall();
  },
  mounted() {
    $(`.slick_ranking`).slick({
      infinite: true,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      prevArrow: $(`.prev_ranking`),
      nextArrow: $(`.next_ranking`),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
  methods: {
    universityCall() {
      axios
        .get(
          `https://api.learn.online/api/lp-university/list/?slug=jain-deemed-to-be-university`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.university_details = resp.data.data[0];
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
  },
};
</script>

<style></style>
