<template>
  <div class="bg-[#f3f4f7]">
    <div
      class="min-h-[220px] max-w-7xl mx-auto flex flex-col justify-center relative"
    >
      <div
        :class="`prev_icons_footer`"
        class="absolute top-[43%] md:top-[37%] left-2 lg:-left-10 z-[501] hidden"
      >
        <img
          src="@/assets/Elements/chevron_left.webp"
          alt=""
          class="w-3 md:w-4"
        />
      </div>
      <div
        :class="`next_icons_footer`"
        class="absolute top-[43%] md:top-[37%] right-2 lg:-right-10 z-[501] hidden"
      >
        <img
          src="@/assets/Elements/chevron_right.webp"
          alt=""
          class="w-3 md:w-4"
        />
      </div>
      <div class="slick_icons_footer">
        <div>
          <div
            class="flex items-center bg-[#001C54] w-[300px] h-[103px] rounded-md mx-auto"
          >
            <div class="py-3 px-4 rounded-md">
              <img
                src="@/assets/Elements/about_jain_section_icon_1.webp"
                alt="Elective icon"
                class=""
              />
            </div>
            <div class="text-white text-start">
              <p class="text-2xl font-bold text-[#fdd335]">40+</p>
              <p class="text-xs font-medium">Electives</p>
            </div>
          </div>
        </div>
        <div>
          <div
            class="flex items-center bg-[#001C54] w-[300px] h-[103px] rounded-md mx-auto"
          >
            <div class="py-3 px-4 rounded-md">
              <img
                src="@/assets/Elements/about_jain_section_icon_2.webp"
                alt="Elective icon"
                class=""
              />
            </div>
            <div class="text-white text-start">
              <p class="text-2xl font-bold text-[#fdd335]">5+</p>
              <p class="text-xs font-medium">
                Leading Global <br />
                Professional Bodies
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            class="flex items-center bg-[#001C54] w-[300px] h-[103px] rounded-md mx-auto"
          >
            <div class="py-3 px-4 rounded-md">
              <img
                src="@/assets/Elements/about_jain_section_icon_3.webp"
                alt="Elective icon"
                class=""
              />
            </div>
            <div class="text-white text-start">
              <p class="text-2xl font-bold text-[#fdd335]">20000+</p>
              <p class="text-xs font-medium">
                Courses on <br />
                LinkedIn learning
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            class="flex items-center bg-[#001C54] w-[300px] h-[103px] rounded-md mx-auto"
          >
            <div class="py-3 px-4 rounded-md">
              <img
                src="@/assets/Elements/about_jain_section_icon_4.webp"
                alt="Elective icon"
                class=""
              />
            </div>
            <div class="text-white text-start">
              <p class="text-2xl font-bold text-[#fdd335]">2000+</p>
              <p class="text-xs font-medium">Hiring Corporates</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-[#001C54] py-4 text-white">
      <p>© Copyrights JAIN (Deemed-to-be University) All Rights Reserved</p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  mounted() {
    $(`.slick_icons_footer`).slick({
      infinite: true,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      prevArrow: $(`.prev_icons_footer`),
      nextArrow: $(`.next_icons_footer`),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
};
</script>

<style></style>
