<template>
  <div class="w-[380px]">
    <div class="flex items-center justify-center h-full">
      <div
        v-if="this.lead_captured == false"
        class="relative bg-gray-100 rounded-lg w-[360px] z-60"
      >
        <form
          ref="lead_form"
          class="space-y-3 p-8 font-normal md:border rounded-lg h-[460px] bg-white flex flex-col justify-center"
          @submit.prevent="submitLead"
        >
          <form
            @submit.prevent="tap = 2"
            v-show="tap == 1"
            class="font-normal rounded-lg h-[320px]"
          >
            <h1
              class="text-start font-bold text-xl pl-1 h-10 absolute top-6 left-8"
            >
              Enquire Now
            </h1>
            <div class="flex flex-col gap-4 h-[320px]">
              <input
                type="text"
                id="full_name"
                placeholder="Full Name*"
                class="p-1 rounded-md w-full text-gray-800 border border-gray-400 h-10 text-sm bg-gray-100"
                required
              />
              <input
                type="email"
                id="mail"
                placeholder="Email*"
                class="p-1 rounded-md w-full text-gray-800 border border-gray-400 h-10 text-sm bg-gray-100"
                required
              />
              <input
                type="tel"
                id="phone"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                maxlength="10"
                placeholder="Phone*"
                class="p-1 rounded-md w-full text-gray-800 border border-gray-400 h-10 text-sm bg-gray-100"
                required
              />
              <div class="flex gap-4 items-center text-gray-800">
                <select
                  @change="selectElectiveCall"
                  id="program"
                  class="p-1 rounded-md w-full text-gray-500 border border-gray-400 h-10 text-sm bg-gray-100"
                  aria-label="Default select example"
                  required
                >
                  <option selected disabled value="">Choose Program*</option>
                  <option
                    v-for="program in programs"
                    :key="program"
                    :value="program.name"
                    :program_id="program.id"
                  >
                    {{ program.name }}
                  </option>
                </select>
              </div>
              <!-- Elective -->
              <div class="flex gap-4 items-center text-gray-800">
                <select
                  class="p-1 rounded-md w-full text-gray-500 border border-gray-400 h-10 text-sm bg-gray-100"
                  aria-label="Default select example"
                  required
                  id="elective"
                >
                  <option selected disabled value="">Choose Elective*</option>
                  <option
                    v-for="el in elective_list"
                    :key="el.elective.id"
                    :value="el.elective.name"
                    :elective_id="el.elective.id"
                  >
                    {{ el.elective.name }}
                  </option>
                </select>
              </div>
              <div
                class="flex gap-2 items-start text-gray-800 text-[10px] text-start"
              >
                <input type="checkbox" checked />
                <span
                  >I authorize JAIN Online and its associates to contact me with
                  updates & notifications via email, SMS, WhatsApp, and voice
                  call. This consent will override any registration for
                  DNC/NDNC.</span
                >
              </div>
            </div>
            <div
              class="flex justify-between items-center pt-2 absolute bottom-4 right-0 w-full"
            >
              <div class="w-1/2">
                <p class="hidden">Prev</p>
              </div>
              <button type="submit" class="w-1/2 flex gap-1 justify-center">
                <div class="text-gray-400 font-semibold pt-1">Next</div>
                <img
                  src="@/assets/Elements/form_arrorw_right.webp"
                  alt="Next arrow"
                />
              </button>
            </div>
          </form>

          <!-- Tap 2 -->
          <form
            @submit.prevent="tap = 3"
            v-show="tap == 2"
            class="font-normal rounded-lg h-[320px]"
          >
            <h1
              class="text-start font-bold text-xl pl-1 h-10 absolute top-6 left-8"
            >
              Personnel Details
            </h1>
            <div class="flex flex-col justify-evenly gap-4 h-[320px]">
              <div class="flex gap-1 items-center text-gray-800">
                <select
                  @change="selectElectiveCall"
                  id="program"
                  class="p-1 rounded-md w-full border border-gray-400 h-10 text-sm bg-gray-100"
                  aria-label="Default select example"
                  required
                >
                  <option selected disabled value="">Select Gender*</option>
                  <option
                    v-for="el in ['Male', 'Female']"
                    :key="el"
                    :value="el"
                  >
                    {{ el }}
                  </option>
                </select>
                <div class="flex gap-4 items-center text-gray-800">
                  <input
                    type="date"
                    class="p-1 rounded-md w-full text-gray-800 border border-gray-400 h-10 text-sm bg-gray-100"
                  />
                </div>
              </div>

              <div class="flex gap-4 items-center text-gray-800">
                <select
                  @change="selectElectiveCall"
                  id="program"
                  class="p-1 rounded-md w-full border border-gray-400 h-10 text-sm bg-gray-100"
                  aria-label="Default select example"
                  required
                >
                  <option selected disabled value="">Nationality*</option>
                  <option
                    v-for="el in ['Indian', 'Others']"
                    :key="el"
                    :value="el"
                  >
                    {{ el }}
                  </option>
                </select>
              </div>
              <div class="flex gap-4 items-center text-gray-800">
                <select
                  @change="selectElectiveCall"
                  id="program"
                  class="p-1 rounded-md w-full border border-gray-400 h-10 text-sm bg-gray-100"
                  aria-label="Default select example"
                  required
                >
                  <option selected disabled value="">
                    Country of Residence *
                  </option>
                  <option
                    v-for="el in ['India', 'China', 'USA']"
                    :key="el"
                    :value="el"
                  >
                    {{ el }}
                  </option>
                </select>
              </div>
              <div class="flex gap-4 items-center text-gray-800">
                <textarea
                  type="text"
                  placeholder="Address*"
                  cols="30"
                  rows="5"
                  class="p-1 rounded-md w-full text-gray-800 border border-gray-400 text-sm bg-gray-100"
                ></textarea>
              </div>
            </div>
            <div
              class="flex justify-between items-center pt-2 absolute bottom-4 right-0 w-full"
            >
              <div @click="tap = 1" class="w-1/2 flex gap-1 justify-center">
                <img
                  src="@/assets/Elements/form_arrow_left.webp"
                  alt="Prev arrow"
                />
                <button class="text-gray-400 font-semibold pt-1">Prev</button>
              </div>

              <button type="submit" class="w-1/2 flex gap-1 justify-center">
                <div class="text-gray-400 font-semibold pt-1">Next</div>
                <img
                  src="@/assets/Elements/form_arrorw_right.webp"
                  alt="Next arrow"
                />
              </button>
            </div>
          </form>

          <!-- Tap 3 -->
          <div
            v-show="tap == 3"
            class="space-y-3 font-normal rounded-lg h-[390px]"
          >
            <h1
              class="text-start font-bold text-xl pl-1 h-10 absolute top-6 left-8 flex flex-col"
            >
              Documents
              <span class="text-xs font-normal text-red-400"
                >*(Original scanned copy in colour)</span
              >
            </h1>
            <div class="flex flex-col justify-evenly gap-4 h-[320px] pt-2">
              <div class="flex justify-between border-b py-2 border-gray-200">
                <div>0 Level Markscard</div>
                <div>
                  <label
                    for="0-level"
                    class="border rounded-lg bg-gray-100 px-4 py-1 flex items-center gap-2"
                    >Upload
                    <img
                      src="@/assets/Elements/Upload_icon_form.webp"
                      alt="upload-icon"
                      class="h-3"
                    />
                  </label>
                  <input type="file" id="0-level" class="hidden" required />
                </div>
              </div>
              <div class="flex justify-between border-b py-2 border-gray-200">
                <div>A Level Markscard</div>
                <div>
                  <label
                    for="A-level"
                    class="border rounded-lg bg-gray-100 px-4 py-1 flex items-center gap-2"
                    >Upload
                    <img
                      src="@/assets/Elements/Upload_icon_form.webp"
                      alt="upload-icon"
                      class="h-3"
                    />
                  </label>
                  <input type="file" id="A-level" class="hidden" required />
                </div>
              </div>
              <div class="flex justify-between border-b py-2 border-gray-200">
                <div>UG Degree</div>
                <div>
                  <label
                    for="UG-level"
                    class="border rounded-lg bg-gray-100 px-4 py-1 flex items-center gap-2"
                    >Upload
                    <img
                      src="@/assets/Elements/Upload_icon_form.webp"
                      alt="upload-icon"
                      class="h-3"
                    />
                  </label>
                  <input type="file" id="UG-level" class="hidden" required />
                </div>
              </div>
              <div class="flex justify-between border-b py-2 border-gray-200">
                <div>Passport</div>
                <div>
                  <label
                    for="passport"
                    class="border rounded-lg bg-gray-100 px-4 py-1 flex items-center gap-2"
                    >Upload
                    <img
                      src="@/assets/Elements/Upload_icon_form.webp"
                      alt="upload-icon"
                      class="h-3"
                    />
                  </label>
                  <input type="file" id="passport" class="hidden" required />
                </div>
              </div>

              <div @click="payload" class="">
                <button class="bg-green-600 px-4 py-2 rounded-md text-white">
                  PAY NOW
                </button>
              </div>
            </div>
            <div
              class="flex justify-between items-center pt-2 absolute bottom-4 right-0 w-full"
            >
              <div @click="tap = 2" class="w-1/2 flex gap-1 justify-center">
                <img
                  src="@/assets/Elements/form_arrow_left.webp"
                  alt="Prev arrow"
                />
                <button class="text-gray-400 font-semibold pt-1">Prev</button>
              </div>

              <div @click="tap = 3" class="w-1/2 flex gap-1 justify-center">
                <img src="@/assets/Elements/poweredBy_razorpay.webp" alt="" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        v-if="this.lead_captured"
        class="relative bg-gray-100 text-gray-800 rounded-lg w-[420px] h-fit z-60 p-4 py-10"
      >
        Thank you for your Enquiry! Our team will get back to you shortly.
        <button
          @click="$emit('closeWidget'), (this.lead_captured = false)"
          class="absolute top-1 right-1 md:-top-8 md:-right-8 bg-white rounded-full p-1"
        >
          <svg
            class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      script: `https://checkout.razorpay.com/v1/checkout.js`,
      pay_data: {
        total: 10000,
        title: "hello world",
      },
      result: null,
      tap: 1,
      state_list: [],
      city_list: [],
      universities: [],
      selected_program: "",
      selected_elective: "",
      programs: [],
      btn_load: false,
      elective_list: [],
      is_loading: {
        get_otp: false,
        verify_otp: false,
        enquire: false,
      },
      otp_valid: false,
      lead_captured: false,
      message: {
        text: "",
        status: "",
      },
      is_flash_message: {
        message: "",
        status: "",
        is_popup: false,
      },
    };
  },

  emits: ["closeWidget"],
  async created() {
    // await this.createPayment(this.pay_data.total, this.pay_data.title);
    // this.result = await this.loadRazorPay();
    await this.stateCall();
    await this.programCall();
  },
  mounted() {},
  methods: {
    loadRazorPay() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = this.script;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    },
    createPayment(amount, title) {
      console.log(amount, "order amount");
      let data = {
        amount: parseInt(amount),
        name: title,
      };
      axios
        .post(`${process.env.VUE_APP_API}/pay/`, data)
        .then((res) => {
          this.orderData = res.data.payment;
          console.log(this.orderData, "this.orderData");
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async payload() {
      console.log(this.result);
      const result = this.result;
      if (!result) {
        alert("Failed to load razorpay script");
        return;
      }

      let price = new Intl.NumberFormat("hi-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }).format(this.orderData.amount);

      const options = {
        key: process.env.VUE_APP_RAZORPAY_ID,
        amount: price,
        currency: "INR",
        name: this.orderData.order_product,
        description: this.pay_data.title,
        order_id: this.orderData.id,
        contact: "9629828237",
        image:
          "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    },

    async programCall() {
      await axios
        .get(`https://api.learn.online/api/lead-program/list/?university__id=1`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.programs = resp.data.data;
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    selectElectiveCall(el) {
      this.elective_value = "Select Elective*";

      this.selected_program =
        el.target.selectedOptions[0].getAttribute("program_id");
      axios
        .get(
          `https://api.learn.online/api/lead-program-elective/details/list/?program__id=${this.selected_program}&university__slug=jain-online`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.elective_list = resp.data.data;
            console.log(this.elective_list, "this.elective_list");
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },

    async stateCall() {
      await axios
        .get(`https://api.learn.online/api/lead-state-leadsqaure/list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.state_list = resp.data.data;
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    async cityCall(state_id) {
      await axios
        .get(
          `https://api.learn.online/api/lead-city-leadsqaure/list/?state__id=${state_id}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.city_list = resp.data.data;
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    selectCityCall(el) {
      let selected_state =
        el.target.selectedOptions[0].getAttribute("data-selected");
      this.cityCall(selected_state);
    },
  },
};
</script>

<style scoped>
input[type="text"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: left;
}
</style>
