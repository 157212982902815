<template>
  <div class="w-full bg-white shadow-lg">
    <div
      class="h-16 w-full px-6 flex items-center justify-between max-w-7xl mx-auto"
    >
      <div class="h-full flex items-center gap-8 mx-auto md:mx-0">
        <div>
          <img
            src="@/assets/Elements/JAIN_Online_logo.webp"
            alt="Jain Online logo"
            class="w-full h-8 object-contain"
          />
        </div>
      </div>
      <a
        href="tel:+919294939295"
        target="_blank"
        class="hidden md:flex items-center gap-2 font-bold text-lg justify-center bg-[#001C54] w-[180px] rounded-md h-[40px] text-white"
      >
        <img
          src="@/assets/Elements/top_right_phone_icon.webp"
          alt="Phone Icon"
          class="h-4 object-contain"
        />
        <button class="pt-0.5 text-sm">+91 9294939295</button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationViewWCH",
  data() {
    return {};
  },
  created() {},
};
</script>

<style scoped></style>
