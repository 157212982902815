<template>
  <div class="min-h-[220px] py-10 w-full">
    <div class="max-w-7xl mx-auto flex flex-col gap-5">
      <div class="flex justify-between">
        <h1 class="text-xl md:text-3xl font-semibold text-center w-full">
          About <br class="md:hidden" />
          JAIN (Deemed-to-be University)
        </h1>
        <img
          src="@/assets/Elements/JAIN_University_logo.webp"
          alt=""
          class="hidden md:block"
        />
      </div>
      <p class="text-justify md:text-start text-sm p-2">
        Promoted by JAIN Group, the university is recognized among the top
        universities in India and considered a preferred learner-centric
        destination for students from different countries across the globe.
        Known for its emphasis on education, entrepreneurship, research and
        sports, JAIN (Deemed-to-be University) has some of the best minds in the
        educational and research fields, and centres that inspire
        entrepreneurship and innovative thinking.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
