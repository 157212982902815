<template>
  <div class="w-full bg-[#f3f4f7]">
    <div
      class="max-h-[320px] w-full max-w-6xl mx-auto text-black py-4 md:py-10"
    >
      <div class="inline-block">
        <h1 class="font-bold text-2xl">Our Corporates</h1>
      </div>
      <div class="h-auto">
        <div class="carousel relative w-full">
          <div
            :class="`prev`"
            class="absolute top-[27%] md:top-[37%] left-0 lg:-left-10 z-[501] hidden"
          >
            <img
              src="@/assets/Elements/chevron_left.webp"
              alt=""
              class="w-3 md:w-4"
            />
          </div>
          <div
            :class="`next`"
            class="absolute top-[27%] md:top-[37%] right-0 lg:-right-10 z-[501] hidden"
          >
            <img
              src="@/assets/Elements/chevron_right.webp"
              alt=""
              class="w-3 md:w-4"
            />
          </div>
          <div class="slick_hiring">
            <template v-for="el in hiring_partners" :key="el">
              <div class="min-h-[120px] p-4 cursor-pointer">
                <img
                  :src="el.image"
                  alt=""
                  class="w-[290px] bg-white object-contain mx-auto rounded-lg"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "HiringCorporates",
  data() {
    return {
      hiring_partners: [
        {
          image: require("@/assets/Elements/corporates/place-img1.png"),
        },
        {
          image: require("@/assets/Elements/corporates/place-img2.png"),
        },
        {
          image: require("@/assets/Elements/corporates/place-img3.png"),
        },
        {
          image: require("@/assets/Elements/corporates/place-img4.png"),
        },
        {
          image: require("@/assets/Elements/corporates/place-img5.png"),
        },
        {
          image: require("@/assets/Elements/corporates/place-img6.png"),
        },
      ],
    };
  },
  mounted() {
    $(`.slick_hiring`).slick({
      infinite: true,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      prevArrow: $(`.prev`),
      nextArrow: $(`.next`),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });
  },
};
</script>

<style scoped></style>
