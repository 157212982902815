<template>
  <div class="w-full bg-white">
    <div
      class="min-h-[420px] w-full max-w-6xl mx-auto text-[#001C54] py-10 flex flex-col justify-center"
    >
      <div class="flex flex-col lg:flex-row items-start gap-6 text-justify">
        <div>
          <h1 class="font-bold text-2xl py-4 text-center md:text-start">
            Why Jain Online
          </h1>

          <p class="text-sm text-gray-500 px-4 lg:px-0">
            Online Learning is an ideal option to gear your career goals from
            your comfort zones without putting your future on hold! With Online
            learning growing as a popular choice since the past decade, JAIN
            (Deemed-to-be University) is running an extra mile to launch JAIN
            Online to make education accessible to all. <br />
            <br />
            Fusing competent academic experts and digital innovators, JAIN
            Online seeks to make individuals gain global insights to challenge
            real-world problems and develop global network to achieve long-term
            career goals.
          </p>
        </div>
        <div class="mx-auto w-full">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yRVJAlKVzx8?si=Vk2GCEqb7yW9FQfm"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            class="hidden lg:block"
          ></iframe>
          <iframe
            class="w-full lg:hidden h-[215px] md:h-[380px]"
            src="https://www.youtube.com/embed/yRVJAlKVzx8?si=Vk2GCEqb7yW9FQfm"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
