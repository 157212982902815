<template>
  <div>
    <NavigationView />
    <BannerView />
    <RankingView />
    <OnlineDegreeView />
    <WhyJainOnlineView />
    <OurCorporateView />
    <AboutJain />
    <Footer />
  </div>
</template>

<script>
import NavigationView from "@/components/WCH/NavigationView.vue";
import BannerView from "@/components/WCH/BannerView.vue";
import RankingView from "@/components/WCH/RankingView.vue";
import OnlineDegreeView from "@/components/WCH/OnlineDegreeView.vue";
import WhyJainOnlineView from "@/components/WCH/WhyJainOnlineView.vue";
import OurCorporateView from "@/components/WCH/OurCorporatesView.vue";
import AboutJain from "@/components/WCH/AboutJainOnlineView.vue";
import Footer from "@/components/WCH/FooterView.vue";

export default {
  name: "JainUniversityWCH",
  components: {
    NavigationView,
    BannerView,
    RankingView,
    OnlineDegreeView,
    WhyJainOnlineView,
    OurCorporateView,
    AboutJain,
    Footer,
  },
};
</script>

<style></style>
