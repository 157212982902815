<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: "montserrat";
  -webkit-font-smoothing: "montserrat";
  -moz-osx-font-smoothing: "montserrat";
  text-align: center;
  color: #2c3e50;
}
body {
  font-family: "montserrat";
  color: #212529;
  font-weight: 400;
  background: #fff;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
