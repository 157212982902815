<template>
  <div id="online-degree" class="min-h-screen">
    <div class="py-10 max-w-7xl mx-auto">
      <h1 class="text-2xl font-bold">Online Degree Programs</h1>
      <!-- Desktop -->
      <div class="hidden md:flex justify-center pt-6 gap-1">
        <button
          v-for="el in program_details"
          :key="el"
          class="rounded-t-md min-w-[72px] relative px-2 py-1"
          :class="[
            this.active_program.name == el.name
              ? 'bg-[#001C54] text-white border-[#001C54]'
              : 'bg-white text-[#001C54] border border-gray-200 ',
          ]"
          @click="selectProgram(el.name)"
        >
          {{ el.name }}
        </button>
      </div>
      <!-- Mobile -->

      <div class="flex flex-wrap md:hidden justify-center py-6 gap-4 md:gap-1">
        <button
          v-for="el in program_details"
          :key="el"
          class="rounded-md w-[30%] relative py-2"
          :class="[
            this.active_program.name == el.name
              ? 'bg-[#fdd335] text-black border-[#001C54] px-10 font-semibold after-arrow'
              : 'bg-[#001C54] text-white border border-gray-200 ',
          ]"
          @click="selectProgram(el.name)"
        >
          {{ el.name }}
        </button>
      </div>

      <div
        class="h-0.5 bg-gradient-to-r from-white via-black to-white w-[90%] mx-auto"
      ></div>
      <div class="">
        <div class="flex items-start text-justify flex-col md:flex-row">
          <!-- image -->
          <div class="w-[320px] md:w-[520px] mx-auto">
            <img
              src="@/assets/Elements/program_overview_girl.webp"
              alt=""
              class="w-[320px] md:w-[520px] mx-auto"
            />
          </div>
          <div class="w-full px-6 md:w-1/2 pt-20">
            <h1>{{ this.active_program.desc }}</h1>
          </div>
        </div>
        <div
          v-if="this.active_program.electives"
          class="bg-white px-2 py-8 rounded-lg border-gray-200 shadow-lg m-2"
        >
          <h1 class="text-2xl font-semibold">
            Choose from In-Demand Electives
          </h1>
          <div
            class="flex flex-wrap text-start gap-4 py-10 px-4"
            :class="[
              this.active_program.electives.length > 4
                ? 'justify-start'
                : 'justify-evenly',
            ]"
          >
            <div
              v-for="el in this.active_program.electives"
              :key="el"
              class="flex w-full md:w-[24%] items-start gap-2 py-2 relative electives-list"
            >
              <img
                src="@/assets/Elements/plus_bullet.webp"
                alt="plus icons"
                class="h-4"
              />
              <p v-html="el" class="text-sm"></p>
            </div>
          </div>
        </div>
        <!-- Eligibility -->
        <div
          class="py-10 flex flex-col md:flex-row items-start md:items-center"
        >
          <div class="flex gap-2 items-start">
            <img
              src="@/assets/Elements/eligibility_img.webp"
              alt="Eligible image"
              class="w-20 md:w-46"
            />
            <div class="text-start p-2">
              <h1 class="text-lg md:text-2xl font-semibold">Eligibility</h1>
              <p class="py-2 md:py-5 text-sm md:text-base w-full">
                {{ this.active_program.eligibility }}
              </p>
            </div>
          </div>

          <div class="w-full flex flex-col justify-center items-center gap-2">
            <button class="py-2 px-4 font-semibold rounded-lg bg-[#fdd335]">
              APPLY NOW
            </button>
            <button
              class="px-2 text-white bg-[#001C54] rounded-lg py-1 flex gap-2 items-center text-sm"
            >
              <img
                src="@/assets/Elements/top_right_phone_icon.webp"
                alt=""
                class="h-4"
              />
              <p>Talk to a counsellor</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      program_details: [],
      active_program: [],
    };
  },
  created() {
    this.programCall();
  },
  methods: {
    programCall() {
      axios
        .get(`https://api.learn.online/api/lp-program/list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.program_details = resp.data.data;
            console.log(this.program_details);
            this.active_program = this.program_details[0];
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    selectProgram(name) {
      this.active_program = this.program_details.filter((el) => {
        return el.name == name;
      });
      this.active_program = this.active_program[0];
    },
  },
};
</script>

<style scoped>
#online-degree {
  background-image: url("@/assets/Elements/about_program_bg.webp");
}
.electives-list::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 1px;
  bottom: -1px;
  left: 6px;
  background-color: rgb(212, 211, 211);
}

/* .active-tap::before {
  content: "";
  position: absolute;
  left: -12px;
  border-right: 0px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 26px solid #001c54;
  z-index: 100;
  bottom: 0;
} */

.after-arrow::after {
  content: "";
  position: absolute;
  left: 42px;
  border-right: 14px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: 11px solid #fdd335;
  z-index: 100;
  bottom: -9px;
  rotate: 180deg;
}
</style>
