<template>
  <div class="w-full relative">
    <div class="w-full h-full relative hidden md:block">
      <img
        src="@/assets/Elements/main_full_banner_girl.jpg"
        alt="main banner"
        class="object-contain w-full hidden md:block"
      />

      <div
        class="absolute top-[20%] left-[2%] 2xl:left-[10%] flex flex-col gap-4"
      >
        <h1 class="text-5xl text-start font-bold text-[#001C54]">
          Online <br />
          Degree Programs
        </h1>
        <p
          class="bg-[#001C54] text-sm px-3 py-2 text-white rounded-lg flex justify-evenly"
        >
          <span class="font-semibold border-r pr-3">B.Com</span>
          <span class="font-semibold border-r px-3">BBA</span>
          <span class="font-semibold border-r px-3">M.Com</span>
          <span class="font-semibold border-r px-3">MBA</span>
          <span class="font-semibold border-r px-3">MCA</span>
          <span class="font-semibold">M.A</span>
        </p>
        <div class="flex flex-col gap-2 text-gray-500 font-medium">
          <div class="flex gap-2 items-center">
            <img src="@/assets/Elements/plus_bullet.webp" alt="" class="h-4" />
            <p>16+ Electives</p>
          </div>
          <div class="flex gap-2 items-center">
            <img src="@/assets/Elements/plus_bullet.webp" alt="" class="h-4" />
            <p>Zero Cost EMI Option</p>
          </div>
          <div class="flex gap-2 items-center">
            <img src="@/assets/Elements/plus_bullet.webp" alt="" class="h-4" />
            <p>2000+ Hiring Corporates</p>
          </div>
        </div>
      </div>
      <div
        class="absolute top-[6%] 2xl:top-[15%] right-[2%] 2xl:right-[10%] overflow-y-auto"
      >
        <EnquireView />
      </div>
    </div>
    <!-- Mobile -->
    <div class="md:hidden">
      <div class="flex flex-col gap-4 relative z-10 py-4">
        <h1 class="text-3xl text-center font-bold text-[#001C54]">
          Online <br />
          Degree Programs
        </h1>
        <p
          class="bg-[#001C54] text-xs px-3 py-2 text-white rounded-lg flex justify-evenly w-[97%] mx-auto"
        >
          <span class="font-semibold border-r pr-3">B.Com</span>
          <span class="font-semibold border-r px-3">BBA</span>
          <span class="font-semibold border-r px-3">M.Com</span>
          <span class="font-semibold border-r px-3">MBA</span>
          <span class="font-semibold border-r px-3">MCA</span>
          <span class="font-semibold">M.A</span>
        </p>
        <img
          src="@/assets/Elements/Banner_girl_trans.webp"
          alt="main banner"
          class="object-cover w-full md:hidden h-[260px]"
        />
        <div class="flex flex-col gap-2 text-gray-500 font-medium items-center">
          <div class="flex gap-2 items-center w-[70%] justify-center">
            <p>40+ Electives</p>
          </div>
          <div class="flex gap-2 items-center w-[70%] justify-center">
            <p>Zero Cost EMI Option</p>
          </div>
          <div class="flex gap-2 items-center w-[70%] justify-center">
            <p>2000+ Hiring Corporates</p>
          </div>
        </div>
        <div class="py-5">
          <EnquireView />
        </div>
      </div>
      <img
        src="@/assets/Elements/Banner_BG_Full.webp"
        alt="main banner"
        class="absolute top-0 left-0 object-cover md:object-contain w-full md:hidden h-[520px]"
      />
    </div>
  </div>
</template>

<script>
import EnquireView from "@/components/WCH/EnquireView.vue";
export default {
  name: "BannerViewWCH",
  components: {
    EnquireView,
  },
};
</script>

<style scoped></style>
